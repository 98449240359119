<template>
<div id="app">
  <router-view/>
  <!-- <button @click="takeScreenshot">Сделать скриншот</button> -->
  <div v-if="myCookie">
    <div class="notification">
      <transition-group name="transition-animate">
        <div v-for="item in push_notifications" :key="item.object_id" class="notification-content">
          <div class="content-text">
            <span>{{ item.notification_text }}</span>
          </div>
        </div>
      </transition-group>

      <transition-group name="transition-animate">
        <!-- <div class="notification-ws"> -->
          <div v-for="item in notificationList" :key="item" class="notification-ws-item notification-content" v-show="!item.is_checked">
            <span> {{ item.type }} </span>
            <div>{{ item.title }}</div>
            <button @click="acceptNotification(item); item.is_checked = true;">Подтвердить</button>
          </div>
        <!-- </div> -->
      </transition-group>
      <!-- <transition-group name="transition-animate">
        <div v-for="item in audioNotifications" :ref="`audio-${item.id}`" :key="item.id" class="notification-content audio" v-show="!item.is_checked && item.id === 3">
          <div class="content-text">
            <div>{{ item.text }}</div>
            <audio :src="item.audioUrl" controls crossorigin="anonymous"></audio>
            <button @click.prevent="recordNotificationAnswer" v-if="!isRecording" style="display: block;">Ответить</button>
            <button @click.prevent="audioNotificationCheck(item); stopRecordNotificationAnswer(); item.is_checked = true;" v-else style="display: block;">Стоп</button><br>
            <input type="text" v-model="notificationText">
            <button @click="audioNotificationCheck(item); notificationSendText(); item.is_checked = true;">Send</button>
          </div>
        </div>
      </transition-group> -->
    </div>
    <!-- <div class="avatar">
      <div class="wrapper">
        <router-link to="/messenger" class="notifications" style="right: 132px;">
          <img id="center" src="@/assets/images/chat-preview-icon.png" style="height:35px;">
        </router-link>
        <div class="notifications">
          <img v-if="new_notifications.length" id="center" src="@/assets/images/MainPage/NotificationUncheked.png" @click="show_center_window(!center_window)">
          <img v-else id="center" src="@/assets/images/MainPage/NotificationCheked.png" @click="show_center_window(!center_window)">
          <div v-if="center_window" class="notifications">
            <div class="notifications_body">
              <div class="notifcations_header">
                <a id="check_a" @click="check_all_notifications">
                  <img src='@/assets/images/NotificationsCenter/NotificationsCheckAll.png' alt="notification">
                </a>
                <p>Уведомления</p>
                <img id="close" src='@/assets/images/NotificationsCenter/NotificationsCenterCloseWhite.png' alt="close" @click="show_center_window(false)">
              </div>
              <div class="notifications_overflow">
              <div v-for="item in notifications" :key="item.id" id="item_div" :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}" @click="check_notification(item)">
                <img v-if="item.type === 'task'" src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
                <img v-if="item.type === 'user'" src="@/assets/images/NotificationsCenter/NotificationsTypeUser.svg">
                <p>{{ item.text }}</p>
              </div>
              <div v-for="item in audioNotification" :key="item.id" id="item_div" :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}" @click="check_notification(item)">
                <img v-if="item.type === 'task'" src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
                <img v-if="item.type === 'user'" src="@/assets/images/NotificationsCenter/NotificationsTypeUser.svg">
                <p>{{ item.text }}</p>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div id="admin" @click="show_modal_window(!modal_window)">
          <img v-if="myCookie" class="miniavatar" :src="url($store.state.DATA_USER.photo_url)" alt="avatar"/>
          <img v-else class="miniavatar" src="@/assets/images/avatarka.png" alt="avatar"/>
        </div>
      </div>
      <div v-if="modal_window" id="modal" class="modal" @click="show_modal_window(false)">
        <img v-if="myCookie" class="miniavatar" :src="url($store.state.DATA_USER.photo_url)" alt="avatar"/>
        <img v-else class="miniavatar" src="@/assets/images/avatarka.png" alt="avatar"/>
        <router-link id="navigation_lk_link" class="navigation__navbarUser" :to="{ name: $store.state.ROLE_URL }">
          <div class="elem">
            <img src="@/assets/images/users.png" alt="lk"/>
            <span class="span">Личный кабинет</span>
          </div>
        </router-link>
        <router-link id="page" class="navigation__navbarUser" :to="{ name: 'Settings' }">
          <div class="elem">
            <img src="@/assets/images/settings.png" alt="settings"/>
            <span class="span">Мой профиль</span>
          </div>
        </router-link>
        <div class="line"></div>
        <div id="logout" @click="logout">
          <button id="exit" type="button" class="btn_exit">
            <img src="@/assets/images/exit.png" alt="exit">Выйти
          </button>
        </div>
      </div>
    </div> -->
  </div>
  <div class="instriction" v-if="window_instruction">
    <h3>Документация к использованию CRM системы</h3>
    <h4>Выберите интересующий пункт</h4>
    <hr>
    <div class="column_main">
      <h2>ОБЩИЕ ВОЗМОЖНОСТИ</h2><br>
      <div class="list_istruction">
        <h2><a href="#instruction_main">Главная</a></h2>
        <h2><a href="#instruction_work">Рабочее пространство</a></h2>
        <br>
        <h2><a href="#instruction_task">Доска задач</a></h2>
        <br>
        <h2><a href="#instruction_user">Мои настроики</a></h2>
        <br>
        <h2><a href="#instruction_usertwo">Личный кабинет</a></h2>
        <br>
        <h2><a href="#instruction_notifications">Уведомнления</a></h2>
        <h2><a href="#instruction_chat">Чат</a></h2>
        <br>
        <h2>Мой профиль</h2>
      </div>
    </div>
    <hr>
    <h3>Документация</h3>
    <div class="column_main">
      <h2 id="instruction_main">ГЛАВНАЯ</h2>
      <p>В разделе главная Вы можете просматривать текущие задачи, а так же пользователей в сети и
      читать новостную ленту</p>
      <br>
      <hr>
      <h2 id="instruction_work">РАБОЧЕЕ ПРОСТРАНСТВО</h2>
      <p>В этом разделе вы можете создавать задачи, брать в работу, редактировать, оклонять и оставлять комментарии
      </p>
      <hr>
      <h2 id="instruction_task">ДОСКА ЗАДАЧ</h2>
      <p>
        На доске проводятся операции над задачами. При нажатии на задачу открывается слайдбар. В нём видна вся информация о
        текущей задачи, так же можно
        отказаться, взять в ожидание, взять в работу, завершить и подтвердить задачу. Так же можно оставить комментарий и
        они будут видны в разделе комментарии <br><br>
        1.Чтобы создать задачу нажмите на кнопку + в шапке раздела. затем откроется модальное окно для создание новой задачи.
        В окне необходимо указать исполнителя, название задачи, тип самой задачи, приоритет, дедлайн, так же
        есть возможность добавить наблюдателя и по желанию добавить подзадачу. <br><br>
        2.Чтобы отклонить задание нажмите кнопку отклонить, всплывёт окно с комментариями. Вы должны прокоментировать почему
        Вы отказались от задачи. Так же в будущем будет возможность голосового ответа на принятие или отклонение задачи<br><br>
        3.Так же задачу можно завершить и подтвердить
      </p>
      <hr>
      <h2 id="instruction_user">МОИ НАСТРОЙКИ</h2>
      <p>
        В данном разделе видна информация о текущем пользователе
        так же в будущем появится возможность редактирование данных
      </p>
      <hr>
      <h2 id="instruction_usertwo">ЛИЧНЫЙ КАБИНЕТ</h2>
      <p>
        В личном кабинете можно создавать, редактировать, удалять пользователей, сотрудников, обращения, отчёты, аналитику, комментарии,роли,
        отдел, направления и оценки. <br><br>
        1.Пользователи - в этом разделе есть возможность создавать, удалять и редактировать пользовательские
        данные, а так же есть возможность фильтрации пользовательей такие как обычные, заблокированные и все.
        Так же есть поисковая строка определенного пользователя<br><br>
        2.Сотрудники - Есть возможность создавать и редактировать сотрудника, а так же удалять его<br><br>
        3.Обращения - чтобы создать обращение необходимо выбрать клиента и тип обращения и заполнить необходимые поля.<br><br>
        4.Отчёты - В разделе можно создать отчёт в exel формате, так же есть фильтрация отчётов и фильтр за какой периуд показать
        отчёты. Чтобы создать отчёт нужно указать название и описание<br><br>
        5.Аналитика - можно создать аналитику указав периуд и пользователей выводимых в отчёте.<br><br>
        6.Комментарии - в разделе видны комментарии к задачам, а так же к отклонённым задачам<br><br>
        7.Роли - тут можно создать, редактировать и удалять роль у сотрудника<br><br>
        8.Направления - создание и редактирование направлений <br><br>
        9.В разделе оценки можно оставить оценку укзав клиента, плюсы и минусы, а так же ОБЯЗАТЕЛЬНО ЗАКРЫТОЕ ОБРАЩЕНИЯ
      </p>
      <hr>
      <h2 id="instruction_user">МОИ НАСТРОЙКИ</h2>
      <p>
        В данном разделе видна информация о текущем пользователе
      </p>
      <hr>
      <h2 id="instruction_notifications">УВЕДОМЛЕНИЯ</h2>
      <p>
        Когда происходят какие либо события, к примеру задача в ожидании. В правем верхнем углу
        иконка колокольчика ометится красным цветом и при нажатии на неё, будут видны произошедшие события
      </p>
      <hr>
      <h2 id="instruction_chat">ЧАТ</h2>
      <p>
        В этом разделе можно создавать чат и добавлять в них сотрудников для каких-либо обсуждений
        в чате есть возможность отправка голосового сообщения, а так же прикрепить медиа файл при нажатии на
        кнопку +
      </p>
      <hr>
    </div>
  </div>
  <!-- <div class="instruction_help" v-if="showInstruction">
    <button>Нужна помощь ? нажмите клавишу F1</button>
  </div> -->
</div>
</template>

<script>
/* eslint-disable */
import { getUserNotifications, getTaskById } from '@/api/get'
import { refreshTocken } from '@/api/post'
import { patchPushNotification, patchCheckNotification } from '@/api/patch'
import notificationSound from '@/assets/audio/notification.mp3'
import store from './store'
import html2canvas from 'html2canvas'
// import VueCookies from 'vue-cookies'
import { jwtDecode } from "jwt-decode";
import router from './router'

import * as sodium from 'libsodium-wrappers';

export default {
  name: 'app',
  data () {
    return {
      userId: this.$cookies.get('ID'),
      myCookie: this.$cookies.get('JWT'),
      coock: this.$cookies,
      center_window: false,
      modal_window: false,
      notifications: [],
      audioNotifications: [],
      push_notifications: [],
      new_notifications: [],
      interval: null,
      visibleAudioNotification: false,
      audioWs: null,
      isRecording: false,
      audioUrl: null,
      audioChunks: [],
      audioBlob: null,
      audioBlobBase64: null,
      recordingState: null,
      transcript: null,
      wsRecordUser: null,
      notificationText: null,
      notifyWs: null,
      window_instruction: false,
      showInstruction: true,
      deadline_tasks: [],
      blob_file: '',
      blob: null,
      blobUrl: null,
      blobArr: null,
      notificationList: [],
      mouseX: 0,
      mouseY: 0,
      mouseXPrev: 1,
      mouseYPrev: 1,
      notificationWs: null,
      notificationJoinOrganizations: [],
      socket: null,
      tokenLifetime: null,
      testData: '',
      testData2: 5,
    }
  },
  methods: {
    check_API () {
      getUserNotifications().then((response) => {
        this.notifications = response
        this.push_notifications = this.notifications.filter((item) => !item.is_pushed)
        this.new_notifications = this.notifications.filter((item) => !item.is_checked)
        this.notificationJoinOrganizations = this.notifications.filter((item) => item.notification_reason === 'NEW_MEMBER')
        // if(data.notification_reason == 'NEW_MEMBER') {
        //   this.notificationJoinOrganizations.push(data)
        // }

        // Создаем копию массива уведомлений для безопасной итерации
        const unprocessedNotifications = [...this.push_notifications]

        // Проходим по каждому уведомлению
        unprocessedNotifications.forEach((notification) => {
          setTimeout(() => {
            if (this.push_notifications.some((item) => item.id === notification.id)) {
              // Обновляем статус уведомления
              patchPushNotification(notification.id).then(() => {
                this.push_notifications = this.push_notifications.filter((item) => item.id !== notification.id)
                const audio = new Audio(notificationSound)
                audio.play()
              })
            }
          }, 1000)
        })
      })
    },

    updateMousePosition(event) {
      this.mouseX = event.pageX;
      this.mouseY = event.pageY;
    },
    checkActiveCursor() {
      if(this.mouseX == this.mouseXPrev && this.mouseY == this.mouseYPrev) console.log('Пользователь не активен')
      this.mouseXPrev = this.mouseX
      this.mouseYPrev = this.mouseY
    },
    takeScreenshot() {
      console.log(document)
      html2canvas(document.querySelector('#app')).then(canvas => {
      // html2canvas(document.querySelector('#screenshot-target')).then(canvas => {
        console.log(canvas)
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'screenshot.png';
        link.click();
      });
    },

    playAudio (audio) {
      console.log(audio)
      audio.crossOrigin = 'anonymous'
      audio.play()
    },
    audioNotificationCheck (item) {
      this.blob = null
      this.blobArr = null
      this.audioWs.send({
        str: this.audioBlobBase64,
        bool: true
      })
    },
    async notificationWsMethod () {
      const sodium = require('libsodium-wrappers');
      
      const key = 'keySmrtsft'.padEnd(32, '0')
      let message = this.myCookie;
      
      (async () => {
        await sodium.ready;
        const salt = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
        const iv = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
        const cipherText = sodium.crypto_secretbox_easy(message, iv, key);
        const saltBase64 = sodium.to_base64(salt, sodium.base64_variants.ORIGINAL);
        const ivBase64 = sodium.to_base64(iv, sodium.base64_variants.ORIGINAL);
        const cipherTextBase64 = sodium.to_base64(cipherText, sodium.base64_variants.ORIGINAL);
        const encryptedData = {
          salt: saltBase64,
          iv: ivBase64,
          encryptedMessage: cipherTextBase64
        };

        const encryptedDataString = JSON.stringify(encryptedData)

        this.notificationWs = new WebSocket(
          `${process.env.VUE_APP_SERVER_URL_NOTIFICATIONS}${this.$cookies.get('ID')}`
        )

        this.testData = encryptedDataString

        this.notificationWs.onopen = () => {
          console.log('WebSocket connected ws')
          this.notificationWs.send(encryptedDataString)
        }
        this.notificationWs.onclose = function () {
          console.log('WebSocket closed' )
        }
        this.notificationWs.onerror = function (error) {
          this.testData = error
          console.log('WebSocket error:', error)
        }
        this.notificationWs.onmessage = (event) => {
          this.testData = event.data
          const data = JSON.parse(event.data)
          const audio = new Audio(notificationSound)
          this.push_notifications.push(data)
          audio.play()
          setTimeout(() => {
            this.push_notifications.splice(0, 1)
          }, 4000)
        }
      })()
    },

    async voiceNotificationWsMethod () {
      this.notificationWs = new WebSocket(
        `${process.env.VUE_APP_SERVER_URL_VOICE_NOTIFICATIONS}${this.userId}`
      )
      this.notificationWs.onopen = function () {
        console.log('WebSocket connected voice' )
      }
      this.notificationWs.onerror = function (error) {
        console.log('WebSocket error:', error)
      }
      this.notificationWs.onmessage = (event) => {
        console.log('messege voice')
        const data = JSON.parse(event.data)
      }
    },
    // async audioNotification () {
    //   this.notifyWs = new WebSocket(
    //     `wss://delevopers.ru/test_api/ws/notify/${this.userId}`
    //   )
    //   this.notifyWs.onopen = function () {
    //     console.log('WebSocket connected noty' )
    //   }
    //   this.notifyWs.onclose = function () {
    //     console.log('WebSocket closed noty')
    //   }
    //   this.notifyWs.onerror = function (error) {
    //     console.log('WebSocket error:', error)
    //   }
    //   this.notifyWs.onmessage = (event) => {
    //     if (event.data !== 'None') {
    //       if (typeof(event.data) === 'string') {
    //         const data = JSON.parse(event.data)
    //         for ( let key in data) {
    //           for(let arr in data[key] ) {
    //             const notificationsArray = data[key][arr]
    //             notificationsArray.forEach(taskId => {
    //               getTaskById(taskId).then((response) => {
    //                 console.log('res', response)
    //                 const notificationData = {
    //                   id:response.id,
    //                   title: response.title,
    //                   type: arr,
    //                   is_pushed: true,
    //                   is_checked: false
    //                 }
    //                 this.notificationList.push(notificationData) })
    //             })
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    show_center_window (value) { this.center_window = value },
    show_modal_window (value) { this.modal_window = value },
    url (url) { return `${process.env.VUE_APP_SERVER_URL}/${url}` },
    check_all_notifications () {
      for (const notification of this.notifications) this.check_notification(notification)
      this.new_notifications = []
    },
    check_notification (notification) {
      if (notification.type === 'task') {
        this.$router.push({ name: 'Workspace/PersonalDashboard' })
      } else if (notification.type === 'user') {
        this.$router.push({ name: 'LkSysAdmin/Users' })
      }
      if (notification.is_checked) return
      patchCheckNotification(notification.id).then(() => {
        notification.is_checked = true
        this.new_notifications = this.new_notifications.filter((item) => item.id !== notification.id)
      })
    },
    logout () {
      this.$store.dispatch('logout')
      this.$router.go({ name: 'Main' })
    },
    isAudioChecked (item) {
    },
    recordNotificationAnswer () {
      this.audioUrl = null
      this.audioChunks = []
      // eslint-disable-next-line
      this.recognition = new webkitSpeechRecognition()
      // Устанавливаем параметры распознавания
      this.recognition.lang = 'ru-RU' // Указываем язык речи
      this.recognition.continuous = false // Остановить распознавание после первого результата

      this.recognition.onresult = event => {
        this.transcript = event.results[0][0].transcript
      }

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.mediaRecorder = new MediaRecorder(stream)

          this.mediaRecorder.addEventListener('dataavailable', event => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data)
            }
          })

          this.mediaRecorder.addEventListener('stop', () => {
            this.audioBlob = new Blob(this.audioChunks, { type: 'audio/mp3' })
            const reader = new FileReader()
            reader.onload = () => {
              const base64AudioData = reader.result.split(',')[1]
              this.audioBlobBase64 = base64AudioData
            }
            reader.readAsDataURL(this.audioBlob)
            this.recordingState = null
          })

          this.mediaRecorder.start()
          this.isRecording = true
          this.recordingState = 'recording'
          this.recognition.start() // Запускаем распознавание речи при начале записи
        })
        .catch(error => {
          console.error('Error accessing the microphone: ', error)
        })
    },
    stopRecordNotificationAnswer () {
      this.mediaRecorder.stop()
      this.isRecording = false
      this.recordingState = 'stopped'

      // this.recognition.stop() // Останавливаем распознавание речи после вызова stopRecording()
      setTimeout(() => {
        console.log('Распознанный текст:', this.transcript)
        this.wsRecordUser = new WebSocket(
          `wss://delevopers.ru/test_api/ws/user_record/${this.userId}`
        )
        this.wsRecordUser.onopen = function () {
          console.log('WebSocket connected')
        }
        setTimeout(() => {
          this.wsRecordUser.send(this.audioBlobBase64)
          this.wsRecordUser.send('DONE')
        }, 1500)
        // this.messages.push({ text: this.transcript, user_id: this.userId })
        // if (this.transcript !== null) {
        //   this.sendMessage(this.transcript)
        // }
      }, 1000)
    },
    notificationSendText () {
      this.wsRecordUser = new WebSocket(
        `wss://delevopers.ru/test_api/ws/user_record/${this.userId}`
      )
      this.wsRecordUser.onopen = function () {
        console.log('WebSocket user_record connected')
      }
      setTimeout(() => {
        this.wsRecordUser.send(this.notificationText)
        this.wsRecordUser.send('DONE')
      }, 1500)
    },
    handleKeyPress (event) {
      if (event.code === 'F1') {
        this.window_instruction = !this.window_instruction
        event.preventDefault()
      }
    },
    watchTocken () {
      if (this.$cookies.get('JWT')) {
        const tokenData = jwtDecode(this.$cookies.get('JWT'))
        const expirationTime = tokenData.exp;
        const currentTime = Math.floor(Date.now() / 1000);
        this.tokenLifetime = expirationTime - currentTime
        if(this.tokenLifetime < 60) refreshTocken()
      } else if (this.$cookies.get('REFRESH_JWT')) refreshTocken()
      setTimeout(this.watchTocken, 10000)
    }
  },
  mounted () {
    if(this.$cookies.get('ID')) {
      this.notificationWsMethod()
    }
    else {
      this.interval = setInterval(() => {
        if(this.$cookies.get('ID')) {
          this.userId = this.$cookies.get('ID')
          this.notificationWsMethod()
          clearInterval(this.interval);
        }
      }, 2000);
    }

    if(this.$cookies.get('REFRESH_JWT')) {
      this.watchTocken()
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.updateMousePosition);
  },
}
</script>

<style>
  @import "@/assets/css/style.css";
  .notification-content.audio {
    height: auto;
  }
  .notification-content.audio audio {
    margin-top: 10px;
  }
  .instriction {
    position: fixed;
    top: 0px;
    background: rgba(0, 0, 0, 0.856);
    width: 100%;
    height: 100vh;
    z-index: 60;
    overflow-y: scroll;
  }
  .instriction h3,
  .instriction h4,
  .column_main h2 {color: #fff; margin-top: 10px;}
  .list_istruction h2 {cursor: pointer;}
  .list_istruction h2:hover {background: #ECC22C; color: black;}
  .instriction h3 {
    text-align: center;
    font-size: 30px;
    margin-top: 90px;
  }
  .instriction h4 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
  }
  .column_main {
    margin-left: 10%;
    padding: 10px;
  }
  .column_main p {
    width: 400px;
    margin-top: 10px;
    color: #fff;
    font-size: 20px;
  }
  .list_istruction a {color: #fff;}
  hr {margin: 10px;}
  .instruction_help {
    position: absolute;
    bottom: 150px;
    right: 100px;
  }
  .instruction_help button {
    width: 200px;
    height: 50px;
    border-radius: 15px;
    border: none;
    background: #ECC22C;
    cursor: pointer;
    font-size: 17px;
  }
  .instruction_help button {
  animation: flashAnimation 1.5s infinite;
}

@keyframes flashAnimation {
  0% {
    background-color: #ECC22C;
  }
  50% {
    background-color: rgb(70, 68, 68);
  }
  100% {
    background-color: #ECC22C;
  }
}
</style>
