<template>
  <div v-if="notifications_visible" class="notifications">
    <div class="notifications_body">
      <div class="notifcations_header">
        <a id="check_a" @click="check_all_notifications">
          <img src='@/assets/images/NotificationsCenter/NotificationsCheckAll.png' alt="notification">
        </a>
        <p>Уведомления</p>
        <button @click="changeNotificationsVisible" class="button-close" >×</button>
      </div>
      <div class="notifications_overflow">
        <!-- <div
          v-for="item in notifications"
          :key="item.id" id="item_div"
          :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}"
          @click="check_notification(item)"
        >
          <img src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
          <p>{{ item.notification_data.notification_text }} </p>
        </div> -->
        <div
          v-for="item in new_notifications"
          :key="item.id" id="item_div"
          :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}"
          @click="check_notification(item)"
        >
          <img src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
          <p>{{ item.notification_data.notification_text }} </p>
        </div>
        <div
          v-for="item in viewed_notifications"
          :key="item.id" id="item_div"
          :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}"
          @click="check_notification(item)"
        >
          <img src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
          <p>{{ item.notification_data.notification_text }} </p>
        </div>

      <!-- <div
        v-for="item in audioNotification"
        :key="item.id" id="item_div"
        :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}"
        @click="check_notification(item)"
      >
        <img v-if="item.type === 'task'" src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
        <img v-if="item.type === 'user'" src="@/assets/images/NotificationsCenter/NotificationsTypeUser.svg">
        <p>{{ item.text }}</p>
      </div> -->
    </div>
    </div>
  </div>

  <div class="layout" :class="{'dark-bg-secondary': theme === 'dark'}">
    <div class="layout-header">
      <div class="layout-logo" :class="{'dark-bg-primary': theme === 'dark'}">
        <img src="@/assets/images/menu.png">
        <span>smartsoft</span>
      </div>
      <ul class="layout-nav">
        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'main', 'dark-layout-nav-active': (theme === 'dark' && active == 'main')}"
          :to="{ name: 'Main' }"
        >
          <img src="@/assets/images/navigation/nav-home.png">
          <span :class="{'dark-text': theme === 'dark'}">Главная</span>
        </router-link>
        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'workSpace','dark-layout-nav-active': theme === 'dark' && active == 'workSpace'}"
          :to="{ name: 'Workspace/PersonalDashboard' }"
        >
          <img src="@/assets/images/navigation/nav-view-table.png">
          <span :class="{'dark-text': theme === 'dark'}">Доска задач</span>
        </router-link>
        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'taskList','dark-layout-nav-active': theme === 'dark' && active == 'taskList'}"
          :to="{ name: 'Tasks_list' }"
        >
          <img src="@/assets/images/navigation/nav-view-board.png">
          <span :class="{'dark-text': theme === 'dark'}">Задачи</span>
        </router-link>
        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'messenger','dark-layout-nav-active': theme === 'dark' && active == 'messenger'}"
          :to="{ name: 'Messenger' }"
        >
          <img src="@/assets/images/navigation/nav-chat-zero.png">
          <span :class="{'dark-text': theme === 'dark'}">Чат</span>
        </router-link>
      </ul>
    </div>
    <div class="layout-footer">
      <ul class="layout-nav">
        <a @click="changeNotificationsVisible" class="nav-link">
          <img v-if="new_notifications.length" src="@/assets/images/navigation/nav-notifications.png" class="nav-img-visible">
          <img v-else src="@/assets/images/navigation/nav-notifications-zero.png" class="nav-img-visible">
          <span :class="{'dark-text': theme === 'dark'}">Уведомления</span>
        </a>
        <!-- <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'settings','dark-layout-nav-active': theme === 'dark' && active == 'settings'}"
          :to="{ name: 'Settings' }">
          <img src="@/assets/images/navigation/nav-settings.png" class="nav-img-visible">
          <span :class="{'dark-text': theme === 'dark'}">Настройки</span>
        </router-link> -->
        <a
          class="nav-link"
          href="/Account"
          :class="{'layout-nav-active': active == 'account','dark-layout-nav-active': theme === 'dark' && active == 'account'}"
        >
          <!-- <img src="@/assets/images/navigation/nav-account.png" class="nav-img-visible"> -->
          <img :src="getImgUrl(user_photo)" width="24" height="24" alt="photo" class="nav-img-visible" style="margin-left: -2px;"/>
          <span :class="{'dark-text': theme === 'dark'}">Учетная запись</span>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VueCookies from 'vue-cookies'
import { getUserNotifications, getOrganizationMember, getUserActive } from '@/api/get'
import { patchPushNotification, patchCheckNotification, acceptUserNotification } from '@/api/patch'
import { getUserTasks, getCreatorTasks } from '@/api/post'
import notificationSound from '@/assets/audio/notification.mp3'
import getImgUrl from '@/mixins/getImgUrl.vue'
export default {
  mixins: [getImgUrl],
  data () {
    return {
      userId: '',
      myCookie: this.$cookies.get('JWT'),
      theme: VueCookies.get('THEME'),
      tasks: [],
      notifications: [],
      audioNotifications: [],
      push_notifications: [],
      new_notifications: [],
      viewed_notifications: [],
      notifications_visible: false,
      profile_visilbe: false,
      user_photo: '',
    }
  },
  props: {
    active: String
  },
  methods: {
    check_API () {
      getUserNotifications().then((response) => {
        this.new_notifications = response.filter(el => !el.is_checked)
        this.viewed_notifications = response.filter(el => el.is_checked)
        this.new_notifications.sort((a, b) => new Date(b.notification_data.notification_send_time) - new Date(a.notification_data.notification_send_time))
        this.viewed_notifications.sort((a, b) => new Date(b.notification_data.notification_send_time) - new Date(a.notification_data.notification_send_time))
      })
    },
    async fetchTasks () {
      try {
        let tasks = []
        const filterType = 'user'
        const { data } = await getOrganizationMember(this.$cookies.get('ID'))
        const organizationId = data.organization_id
        if (filterType === 'user') {
          const { data } = await getUserTasks(this.$cookies.get('ID'), organizationId)
          tasks = data
        } else if (filterType === 'me_creator') {
          const { data } = await getCreatorTasks(this.createrID, organizationId)
          tasks = data
        }
        this.tasks = tasks
      } catch (error) {
        this.error = true
      }
    },
    checkDeadlineTasks () {
      // const currentDate = new Date().toISOString().slice(0, 19)
      // const dulicateTasks = [...this.tasks]
      // dulicateTasks.forEach(task => {
      //   if (task.deadline < currentDate) {

      //   }
      // })
    },
    playAudio (audio) {
      audio.crossOrigin = 'anonymous'
      audio.play()
    },
    audioNotificationCheck (item) {
      this.audioWs.send({
        str: this.audioBlobBase64,
        bool: true
      })
    },
    audioNotification () {
      this.notifyWs = new WebSocket(
        `wss://delevopers.ru/api/ws/notify/${this.userId}`
      )
      this.notifyWs.onopen = function () {
        console.log('WebSocket connected nav')
        this.send('текст')
      }
      this.notifyWs.onclose = function () {
        console.log('WebSocket closed')
      }
      this.notifyWs.onerror = function (error) {
        console.log('WebSocket error:', error)
      }
      this.notifyWs.onmessage = (event) => {
        if (event.data !== 'None') {
          const tasks = JSON.parse(event.data)
          if (event.data === 'У вас есть новые задания') {
            this.audioWs = new WebSocket(
              `wss://delevopers.ru/api/ws/record/${this.userId}`
            )
            this.audioWs.onopen = function () {
              console.log('WebSocket connected')
            }
            this.audioWs.onerror = function (error) {
              console.log('WebSocket error:', error)
            }
            this.audioWs.onclose = function () {
              console.log('WebSocket closed')
            }
            let i = 1
            this.audioWs.onmessage = (event) => {
              console.log('WebSocket message:', event.data)
              if (event.data === 'Подключено к вебсокету, для принятия сообщений отправьте текст' && i === 1) {
                i++
                this.audioWs.send('текст')
              } else {
                var blob = new Blob([event.data], { type: 'audio/wav' })
                var blobUrl = URL.createObjectURL(blob)
                // const audio = new Audio(blobUrl)

                i++

                this.audioNotifications.push({
                  id: i,
                  text: 'Новое уведомление',
                  // eslint-disable-next-line
                  is_checked: i === 5 ? true : false,
                  is_pushed: true,
                  type: 'task',
                  audioUrl: blobUrl
                })
              }
            }
          }
        }
      }
    },
    check_all_notifications () {
      for (const notification of this.new_notifications) this.check_notification(notification)
      this.new_notifications = []
    },
    check_notification (notification) {
      acceptUserNotification(notification.notification_data.notification_id)
        .then(res => this.check_API())
      // if (notification.type === 'task') {
      //   this.$router.push({ name: 'Workspace/PersonalDashboard' })
      // } else if (notification.type === 'user') {
      //   this.$router.push({ name: 'LkSysAdmin/Users' })
      // }
      // if (notification.is_checked) return
      // patchCheckNotification(notification.id).then(() => {
      //   notification.is_checked = true
      //   this.new_notifications = this.new_notifications.filter((item) => item.id !== notification.id)
      // })
    },
    changeNotificationsVisible () {
      this.notifications_visible = !this.notifications_visible
    },
    changeProfileVisible () {
      this.profile_visilbe = !this.profile_visilbe
    }
  },
  computed: {
    notificationsHasNew() {
      const res = this.notifications.find(el => el.is_checked === false)
      if (res) return true
      return false
    }
  },
  mounted () {
    this.check_API()
    if (this.myCookie) this.interval = setInterval(this.check_API, 6000)
    getUserActive()
      .then(res => this.user_photo = res.photo_url)

    // if (this.myCookie) this.intervalTasks = setInterval(this.checkDeadlineTasks, 5000)
    // else {
    //   clearInterval(this.interval)
    //   clearInterval(this.intervalTasks)
    // }
    // this.$store.commit('connectChats')
    // this.audioNotification()
    // window.addEventListener('keydown', this.handleKeyPress)
    // setTimeout(() => {
    //   this.showInstruction = false
    // }, 6000)
  }
}
</script>
