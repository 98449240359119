import axios from 'axios'
import { Header } from '@/api/index'
import { getUserActive } from '@/api/get'

// Appeal
export const patchBlockAppeal = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}appeals/${id}/block`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка обращения):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverAppeal = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}appeals/${id}/unblock`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление обращения):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

// Departament
export const patchBlockDepartament = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}departaments/${id}/block`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverDepartament = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}departaments/${id}/unblock`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

// Direction
export const patchChangeDirection = (data) => {
  const direction = { title: data.title }

  getUserActive()

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}directions/${data.id}`, direction, { headers: Header.json })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (редактирование направления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Введены неккоректные данные.')
    })
}

export const patchBlockDirection = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}directions/${id}/block`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка направления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverDirection = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}directions/${id}/unblock`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление направления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

// Employee
export const patchBlockEmployee = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}employees/${id}/block`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка сотрудника):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverEmployee = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}employees/${id}/unblock`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление сотрудника):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

// Estime
export const patchChangeEstime = (data) => {
  const estime = {
    client_id: data.client_id,
    appeal_id: data.appeal_id,
    positive: data.positive,
    negative: data.negative,
    score: data.score
  }

  getUserActive()

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}estimates/${data.id}`, estime, { headers: Header.json })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (редактирование оценки):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Введены неккоректные данные.')
    })
}

export const patchBlockEstime = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}estimates/${id}/block`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка оценки):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverEstime = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}estimates/${id}/unblock`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление оценки):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

// Notification
//
export const patchPushNotification = (id) => {
  getUserActive()

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}notifications/${id}/push`)
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (push уведомления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}
//
export const patchCheckNotification = (id) => {
  getUserActive()

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}notifications/${id}/check`)
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (check уведомления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const acceptUserNotification = (id) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}notifications_service/notifications/${id}`, null, { headers: Header.auth })
    .then((response) => { if (response.data) return response.data })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение уведомлений пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

// Role
export const patchChangeRole = (data) => {
  const role = { title: data.title }

  getUserActive()

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}roles/${data.id}`, role, { headers: Header.json })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (редактирование роли):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Введены неккоректные данные.')
    })
}

export const patchBlockRole = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}roles/${id}/block`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка роли):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverRole = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}roles/${id}/unblock`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление роли):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

// User
export const patchChangeUser = (data) => {
  const user = {
    name: data.name,
    surname: data.surname,
    patronymic: data.patronymic,
    is_verified: data.is_verified,
    is_superuser: data.is_superuser,
    is_client: data.is_client,
    is_stuff: data.is_stuff,
    phone_number: data.phone_number,
    email: data.email,
    role_id: data.role_id
  }

  if (data.telegram_id) user.telegram_id = data.telegram_id
  if (data.direction_id) user.direction_id = data.direction_id

  getUserActive()

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}users/${data.id}`, user, { headers: Header.json })
    .then((response) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (редактирование пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Введены неккоректные данные.')
    })
}

export const patchBlockUser = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}users/${id}/block`, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchRecoverUser = (id) => {
  getUserActive()

  return axios.patch(`${process.env.VUE_APP_SERVER_URL}users/${id}/unblock`, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (восстановление пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchTime = (id) => {
  return axios.patch(`${process.env.VUE_APP_SERVER_URL}work_time/${id}`)
    .then(response => response)
    .catch(error => console.log('ERROR PATCH time user: ', error.toJSON()))
}

export const patchEditReport = (object) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}reports/${object.id}`, { title: object.title, text: object.text }, { headers: Header.json })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchDeleteReport = (object) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}reports/${object.id}/block`, { report_id: object.id }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchDeleteChat = (id) => {
  // const testId = '0b4462a8-7c41-4452-abb9-edbc17e38ded'
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}chats/${id}/block`, { _method: 'patch' }, { headers: Header.json })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      return false
    })
}

export const patchDeleteMessage = (id) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}chat_messages/${id}/block`, { headers: Header.json })
    .catch((error) => {
      console.log(`Ошибка PATCH запроса (блокировка отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен '}.`)
      return false
    })
}

// подтвердить вступление в организацию

export const acceptInviteToOrganization = async (organizationId, userId) => {
  // const testUserId = '8d059777-32a5-4cb5-9c79-10af2cea25bf'

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}organization_members/${userId}/accept?organization_id=${organizationId}`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на вступление в организацию. Введены неккоректные данные.')
    })
}

export const rejectInviteToOrganization = async (organizationId, userId) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}organization_members/${userId}/reject?organization_id=${organizationId}`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на вступление в организацию. Введены неккоректные данные.')
    })
}

export const blockUserInOrganization = async (orgId, userId) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}organization_members/${userId}/block?organization_id=${orgId}}`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на блокировку пользователя.')
    })
}

export const unblockUserInOrganization = async (orgId, userId) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}organization_members/${userId}/unblock?organization_id=${orgId}}`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на разблокировку пользователя.')
    })
}

export const startTask = async (id, time = 2) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${id}/work?time=${time}`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на вступление в организацию. Введены неккоректные данные.')
    })
}

export const cancelTask = async (taskId) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${taskId}/cancel`, { _method: 'patch' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на вступление в организацию. Введены неккоректные данные.')
    })
}

export const updateTask = async (taskId, data) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${taskId}`, data, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка обновления задачи')
    })
}

export const updateUser = async (userId, data) => {
  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}users/${userId}`, data, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на вступление в организацию. Введены неккоректные данные.')
    })
}

export const changeTimesheetData = async (id, data) => {
  /* eslint-disable */ 
  const data2 = { ...data }
  delete data2['day'];
  delete data2['month'];
  delete data2['year'];
  delete data2['user_id'];
  // data2.start_time = '12:00:00'
  // data2.end_time = '12:01:00'
  // delete data2['start_time'];
  // delete data2['end_time'];

  // delete workDay['organization_id'];

  return axios
    .patch(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/${id}`, data2, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка patch запроса')
    })
}
