<template>
  <div v-if="show" @click="showDropdown = false" class="modal-global">
    <div class="modal-container" :class="{'dark-bg-primary': theme === 'dark'}">
      <div class="modal-header">
        <h3 :class="{'dark-text': theme === 'dark'}">Новая задача</h3>
        <button @click="hideWindow" :class="{'dark-text': theme === 'dark'}">×</button>
      </div>
      <div class="modal-body">
        <div class="loading" v-if="isLoading"></div>
        <form @submit.prevent="submit">
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Исполнитель</p>
            <input type="text" v-model="selectedUser" @click.stop="changeShowDropdown" :class="{'dark-input': theme === 'dark'}"/>
            <ul v-if="showDropdown" class="user-list" style="top:32px !important; max-height: 200px;  overflow-y: auto;">
              <li v-for="user in filteredOrgUsers" :key="user.id" @click.prevent="changeSelectedUser(user)">
                {{ user.name }} {{ user.surname }} {{ user.patronymic }}
              </li>
            </ul>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Название</p>
            <input type="text" v-model="taskTitle" :class="{'dark-input': theme === 'dark'}">
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Описание</p>
            <input type="text" v-model="taskText" :class="{'dark-input': theme === 'dark'}">
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Тип задачи</p>
            <select v-model="selectedTypeID" @click="getTypeList()" :class="{'dark-input': theme === 'dark'}">
              <option v-for="type in typeList" :key="type.id" :value="type.id">{{ type.title }}</option>
            </select>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Приоритет задачи</p>
            <select v-model="selectedPriority" :class="{'dark-input': theme === 'dark'}">
              <option value="very_high">Очень высокий</option>
              <option value="high">Высокий</option>
              <option value="average">Средний</option>
              <option value="low">Низкий</option>
              <option value="very_low">Очень низкий</option>
            </select>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Желаемый дедлайн</p>
            <input v-model="formattedDeadline" :class="{'dark-input': theme === 'dark'}"
              @click="visModaldate" v-show="!modalDate" />
            <div v-if="modalDate">
              <DatePicker v-model="deadline" :class="{'dark-datepicker': theme === 'dark'}" transparent borderless mode="dateTime" is24hr></DatePicker>
              <div>
                <button @click="cancelDate">Отмена</button>
                <button @click="visModaldate">Ok</button>
              </div>
            </div>
          </div>

          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">checkpoint</p>
            <input v-model="formattedCheckpoint" :class="{'dark-input': theme === 'dark'}"
              @click="visModalCheckpoint" v-show="!modalCheckpoint" />
            <div v-if="modalCheckpoint">
              <DatePicker v-model="checkpoint" :class="{'dark-datepicker': theme === 'dark'}" transparent borderless mode="dateTime" is24hr></DatePicker>
              <div>
                <button @click="cancelCheckpoint">Отмена</button>
                <button @click="visModalCheckpoint">Ok</button>
              </div>
            </div>
          </div>

          <div class="modal-input-wrapper" v-if="selectedUserID !== userID">
            <p :class="{'dark-text': theme === 'dark'}">checkbox</p>
            <input v-model="formattedCheckbox" :class="{'dark-input': theme === 'dark'}"
              @click="visModalCheckbox" v-show="!modalCheckbox" />
            <div v-if="modalCheckbox">
              <DatePicker v-model="checkbox" :class="{'dark-datepicker': theme === 'dark'}" transparent borderless mode="dateTime" is24hr></DatePicker>
              <div>
                <button @click="cancelCheckbox">Отмена</button>
                <button @click="visModalCheckbox">Ok</button>
              </div>
            </div>
          </div>
          <div class="error-task_create"> {{ error_create }}</div>

          <!-- <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Часовой пояс</p>
            <select v-model="timezone" :class="{'dark-input': theme === 'dark'}">
              <option value="Europe/Samara">Самара (GMT+4)</option>
              <option value="Europe/Moscow">Москва (GMT+3)</option>
            </select>
          </div> -->

          <!-- <div class="modal-input-wrapper modal-input-wrapper2">
            <div class="modal-input-wrapper-flex">
              <span :class="{'dark-text': theme === 'dark'}">Подзадачи</span>
              <a class="add_minitask" @click="addMiniTask()"><img src="@/assets/images/Workspace/AddMiniTask.png"></a>
            </div>
            <div>
              <div class="relative" v-for="(task, index) in miniTasks" :key="index">
                <input class="minitask-input" type="text" v-model="task.title" :class="{'dark-input': theme === 'dark'}">
                <button @click="removeMiniTask(index)" class="delete_minitask">
                  <img src="@/assets/images/Workspace/DeleteMiniTask.png">
                </button>
              </div>
            </div>
          </div> -->

          <!-- <textarea style="min-height: 100px; width:100%" v-model="taskText" placeholder="Описание задачи"></textarea> -->
          <!-- <button :disabled="isCreateButtonDisabled" @click.prevent="startListening" class="layout-header-btn" type="submit">Голосом</button> -->
          <div style="display: flex; justify-content: center">
            <button :disabled="isCreateButtonDisabled" class="layout-header-btn " type="submit">Создать</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Header } from '@/api/index'
import axios from 'axios'
import { postTask, NotificationNewTask } from '@/api/post'
import { getOrganizationMember, getAllUsers, getOrganizationsUsers, getUserById, getUserActive } from '@/api/get'
import { startTask } from '@/api/patch'
import 'v-calendar/style.css'
import { DatePicker } from 'v-calendar'
import VueCookies from 'vue-cookies'
import levenshteinDistance from '@/mixins/LevenshteinDistance.vue'

export default {
  mixins: [levenshteinDistance],
  data () {
    return {
      Users: [],
      usersList: [],
      usersWithName: [],
      userID: this.$cookies.get('ID'),
      theme: VueCookies.get('THEME'),
      organizationID: this.$cookies.get('ORGANIZATION_ID'),
      selectedUser: '',
      selectedUserID: '',
      searchQuery: '',
      task: [],
      taskTitle: '',
      taskText: '',
      taskTitles: '',
      recording: false,

      modalDate: false,
      modalCheckpoint: false,
      modalCheckbox: false,

      typeList: [],
      selectedTypeID: '',
      selectedPriority: 'average',

      showDropdown: false,
      miniTasks: [{ title: '', text: '' }],
      deadline: '',
      checkpoint: '',
      checkbox: '',
      spectators: [],
      taskText: '',
      miniTaskTitle: '',
      miniTaskText: '',
      isCreateButtonDisabled: false,
      isLoading: false,

      // SpeechRecognition: window.SpeechRecognition || window.webkitSpeechRecognition,
      SpeechRecognition: window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition,
      recognition: null,
      voiceEnable: true,
      showDropdown: false,
      orgUsers: [],
      error_create: ''
    }
  },
  components: {
    DatePicker
  },
  props: {
    show: { type: Boolean, default: false }
  },
  computed: {
    filteredOrgUsers () {
      const filtered = []
      const searchQuery = this.selectedUser
      if (this.selectedUser.length >= 0) {
        this.orgUsers.map((el) => {
          if (el.name.toLowerCase().includes(searchQuery.toLowerCase())) filtered.push(el)
          else if (el.surname.toLowerCase().includes(searchQuery.toLowerCase())) filtered.push(el)
        })
      } else {
        this.showDropdown = true
      }
      return filtered
    },
    formattedDeadline () {
      if (this.deadline) {
        const date = new Date(this.deadline)
        const formattedDate = date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
        return formattedDate
      } else {
        return ''
      }
    },
    formattedCheckpoint () {
      if (this.checkpoint) {
        const date = new Date(this.checkpoint)
        const formattedDate = date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
        return formattedDate
      } else {
        return ''
      }
    },
    formattedCheckbox () {
      if (this.checkbox) {
        const date = new Date(this.checkbox)
        const formattedDate = date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
        return formattedDate
      } else {
        return ''
      }
    }
  },
  methods: {
    visModaldate () {
      this.modalDate = !this.modalDate
      this.modalCheckpoint = false
      this.modalCheckbox = false
    },
    visModalCheckpoint () {
      this.modalCheckpoint = !this.modalCheckpoint
      this.modalDate = false
      this.modalCheckbox = false
    },
    visModalCheckbox () {
      this.modalCheckbox = !this.modalCheckbox
      this.modalDate = false
      this.modalCheckpoint = false
    },
    cancelDate () {
      this.modalDate = !this.modalDate
      this.deadline = null
    },
    cancelCheckpoint () {
      this.modalCheckpoint = !this.modalCheckpoint
      this.checkpoint = null
    },
    cancelCheckbox () {
      this.modalCheckbox = !this.modalCheckbox
      this.checkbox = null
    },
    changeShowDropdown () {
      this.showDropdown = !this.showDropdown
    },
    changeSelectedUser(user) {
      this.showDropdown = false
      this.selectedUser = `${user.name} ${user.surname}`
      this.selectedUserID = user.id
    },
    async getUsersList () {
      // this.usersList = await getAllUsers(false)

      getOrganizationsUsers(this.organizationID)
        .then((res) => {
          res.forEach(user => {
            getUserById(user.user_id)
              .then((response) => {
                this.usersWithName.push(response.data)
              })
          })
        })
    },
    searchUser () {
      if (this.selectedUser.length >= 0) {
        this.searchQuery = this.selectedUser

        const params = {
          organization_id: this.organizationID,
          is_blocked: false
        }

        axios.get(`${process.env.VUE_APP_SERVER_URL}organization_members/`, { params: params, headers: Header.auth })
          .then(res => {
            res.data.forEach(user => {
              getUserById(user.user_id)
                .then((response) => {
                  this.Users.push(response.data)
                })
            })
            this.showDropdown = true
          })
          .catch(err => console.log(err))
      } else {
        this.showDropdown = true
      }
    },
    getTypeList () {
      axios.get(`${process.env.VUE_APP_SERVER_URL}task_types/`, {
        params: { organization_id: this.organizationID },
        headers: Header.auth
      })
        .then(res => {
          this.typeList = res.data
        })
        .catch(err => console.log(err))
    },
    clearForm () {
      this.selectedUser = ''
      this.selectedUserID = ''
      this.selectedTypeID = ''
      this.taskTitle = ''
      this.taskText = ''
      this.deadline = '',
      this.checkpoint = '',
      this.checkbox = '',
      this.error_create = ''
    },
    selectUser (user) {
      this.selectedUser = user.name
      this.selectedUserID = user.id
      this.Users = []
      this.searchQuery = ''
    },
    hideWindow () {
      this.voiceEnable = false
      this.recognition.stop()
      this.clearForm()
      this.$emit('close')
    },
    addMiniTask () {
      this.miniTasks.push({ title: '', text: '' })
    },
    removeMiniTask (index) {
      this.miniTasks.splice(index, 1)
    },
    submit () {
      const date = new Date(this.deadline)
      const offset = -date.getTimezoneOffset() / 60;
      const timezone = `+${String(offset).padStart(2, '0')}:${String(offset % 1 * 60).padStart(2, '0')}`
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const deadlineTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:00${timezone}`

      let checkpointTimestamp = null
      if (this.checkpoint) {
        const date = new Date(this.checkpoint)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        checkpointTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:00${timezone}`
      }

      let checkboxTimestamp = null
      if (this.checkbox) {
        const date = new Date(this.checkbox)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        checkboxTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:00${timezone}`
      }

      let deadlineMs = 0
      let checkpointMs = 0
      let checkboxMs = 0
      let currentMs = new Date().getTime()

      if(this.deadline) deadlineMs = this.deadline.getTime()
      if(this.checkpoint) checkpointMs = this.checkpoint.getTime()
      if(this.checkbox) checkboxMs = this.checkbox.getTime()

      // console.log(deadlineMs, checkpointMs, checkboxMs)

      const today = new Date()
      today.setHours(0, 0, 0, 0)
      
      if (deadlineMs < currentMs) {
        this.error_create = 'Неправильно выбран дедлайн'
        return
      }
      else if (!this.selectedUserID) {
        this.error_create = 'Выберите исполнителя'
        return
      }
      else if (!this.taskTitle) {
        this.error_create = 'Введите название задачи'
        return
      }
      else if (deadlineMs < checkpointMs || ((checkpointMs < currentMs) && checkpointMs !== 0)) {
        this.error_create = 'Неправильно выбран чекпоинт'
        return
      }
      else if (deadlineMs < checkboxMs || ((checkboxMs < currentMs) && checkboxMs !== 0)) {
        this.error_create = 'Неправильно выбран чекбокс'
        return
      }

      if (this.selectedUserID) {
        const miniTasksData = []

        this.isCreateButtonDisabled = true
        this.isLoading = true
        const data = {
          executor_id: this.selectedUserID,
          organization_id: this.organizationID,
          title: this.taskTitle,
          deadline: deadlineTimestamp,
          text: this.taskText
          // mini_tasks: [],
          // mini_tasks: miniTasksData,
          // spectators: spectators,
        }
        if (this.selectedTypeID) data.type_id = this.selectedTypeID
        if (this.selectedPriority) data.priority = this.selectedPriority
        if (checkpointTimestamp) data.checkpoint = checkpointTimestamp
        if (checkboxTimestamp && this.selectedUserID !== this.userID) data.checkbox = checkboxTimestamp

        postTask(data)
          .then(response => {
            this.voiceEnable = false
            this.recognition.stop()
            if (this.selectedUserID === this.userID) {
              startTask(response.data.id, 2)
            }
            this.clearForm()
          })
          .finally(() => {
            this.$emit('taskCreated')
            this.isCreateButtonDisabled = false
            this.isLoading = false
          })
      }
    },

    startListening () {
      this.recognition = new this.SpeechRecognition()
      this.recognition.lang = 'ru'
      
      const ArrTaskTitle = [
        'название', 'имя', 'именование'
      ]
      const ArrTaskDescription = [
        'описание', 'списание'
      ]
      const ArrTaskExecutor = [
        'исполнитель', 'исполнители', 'исполнителем', 'автор', 'работник', 'для',
        'назначь', 'назначил', 'назначила', 'назначен', 'назначив'
      ]
      const ArrTaskType = [
        'тип', 'типа', 'типо'
      ]
      const ArrTaskDeadline = [
        'дедлайн', 'деадлайн', 'срок', 'рок', 'дотла', 'онлайн', 'подлая',
        'доделаем', 'до', 'завершение', 'завершения', 'добавлен', 'добиваем',
        'добывает', 'выполнить', 'завершить', 'билайн', 'бланк', 'двоим', '40',
        'подвоем', ' дополнен', 'добываем', 'давай', 'онлайн', 'клайн', 'двоим',
        'подлая', 'накрываем', 'наглая', 'делаем', 'down', 'доволен', 'предлагаем',
        'deadline', 'вдвоем', 'доклад', 'добывать',
      ]
      const ArrTaskCheckpoint = [
        'чекпоинт', 'поинт', 'point', 'точка', 'чекпойнт', 'checkpoint'
      ]
      const ArrTaskCheckbox = [
        'чекбокс', 'бокс', 'checkbox'
      ]
      const ArrTaskDeadlineMonth = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
      ]
      const ArrTaskPriority = [
        'приоритет'
      ]
      const ArrTaskSubmit = [
        'создать', 'завершить', 'создать.'
      ]
      const ArrTaskClose = [
        'закрой', 'закрыть', 'закрыть?'
      ]

      const voiceData = (transcriptArr, type) => {
        let haveMonth = false
        const currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth()
        for (let j = 0; j < transcriptArr.length; j++) {
          const month = transcriptArr[j]
          const day = transcriptArr[j - 1]
          if (ArrTaskDeadlineMonth.includes(month)) {
            if (type === 'deadline') {
              if (!this.deadline) this.deadline = new Date()
            } else if (type === 'checkpoint') {
              if (!this.checkpoint) this.checkpoint = new Date()
            } else if (type === 'checkbox') {
              if (!this.checkbox) this.checkbox = new Date()
            }
            haveMonth = true
            const monthIdx = ArrTaskDeadlineMonth.findIndex(m => m === month)
            if (monthIdx < currentMonth) currentYear++
            if (!isNaN(day)) {
              if (type === 'deadline') {
                this.deadline.setDate(day)
                this.deadline = new Date(this.deadline)
              } else if (type === 'checkpoint') {
                this.checkpoint.setDate(day)
                this.checkpoint = new Date(this.checkpoint)
              } else if (type === 'checkbox') {
                this.checkbox.setDate(day)
                this.checkbox = new Date(this.checkbox)
              }
            }
            if (type === 'deadline') {
              this.deadline.setMonth(monthIdx)
              this.deadline.setFullYear(currentYear)
              this.deadline = new Date(this.deadline)
            } else if (type === 'checkpoint') {
              this.checkpoint.setMonth(monthIdx)
              this.checkpoint.setFullYear(currentYear)
              this.checkpoint = new Date(this.checkpoint)
            } else if (type === 'checkbox') {
              this.checkbox.setMonth(monthIdx)
              this.checkbox.setFullYear(currentYear)
              this.checkbox = new Date(this.checkbox)
            }
            const afterMonth = transcriptArr.slice(j + 1)
            if (afterMonth.length > 1) {
              if (!isNaN(afterMonth[0]) && !isNaN(afterMonth[1])) {
                if (afterMonth[0] && afterMonth[0] < 25) {
                  if (type === 'deadline') {
                    this.deadline.setHours(afterMonth[0])
                  } else if (type === 'checkpoint') {
                    this.checkpoint.setHours(afterMonth[0])
                  } else if (type === 'checkbox') {
                    this.checkbox.setHours(afterMonth[0])
                  }
                }
                if (afterMonth[1] && afterMonth[1] < 61) {
                  if (type === 'deadline') {
                    this.deadline.setMinutes(afterMonth[1])
                  } else if (type === 'checkpoint') {
                    this.checkpoint.setMinutes(afterMonth[1])
                  } else if (type === 'checkbox') {
                    this.checkbox.setMinutes(afterMonth[1])
                  }
                }
              }
            }
          }
        }
        if (!haveMonth) {
          for (let g = 0; g < transcriptArr.length - 1; g++) {
            const hour = transcriptArr[g]
            const minute = transcriptArr[g + 1]
            if (!isNaN(hour)) {
              if (hour >= 0 && hour < 25) {
                if (type === 'deadline') {
                  if (!this.deadline) this.deadline = new Date()
                  this.deadline.setHours(hour)
                  this.deadline = new Date(this.deadline)
                } else if (type === 'checkpoint') {
                  if (!this.checkpoint) this.checkpoint = new Date()
                  this.checkpoint.setHours(hour)
                  this.checkpoint = new Date(this.checkpoint)
                } else if (type === 'checkbox') {
                  if (!this.checkbox) this.checkbox = new Date()
                  this.checkbox.setHours(hour)
                  this.checkbox = new Date(this.checkbox)
                }
              }
              if (!isNaN(minute)) {
                if (type === 'deadline') {
                  if (minute >= 0 && minute < 61) {
                    this.deadline.setMinutes(minute)
                    this.deadline = new Date(this.deadline)
                  }
                } else if (type === 'checkpoint') {
                  if (minute >= 0 && minute < 61) {
                    this.checkpoint.setMinutes(minute)
                    this.checkpoint = new Date(this.checkpoint)
                  }
                } else if (type === 'checkbox') {
                  if (minute >= 0 && minute < 61) {
                    this.checkbox.setMinutes(minute)
                    this.checkbox = new Date(this.checkbox)
                  }
                }
              }
            }
          }
        }
      }

      this.recognition.onresult = async (event) => {
        const transcript = event.results[0][0].transcript.toLowerCase()

        // let transcript = ''
        // for (let i = event.resultIndex; i < event.results.length; i++) {
        //   const result = event.results[i]
        //   transcript += result[0].transcript
        // }
        const transcriptArr = transcript.split(/[,;: ]+/)
        for (let q = 0; q < transcriptArr.length; q++) {
          const lastChar = transcriptArr[q].charAt(transcriptArr[q].length - 1)
          if (lastChar === '.' || lastChar === ',') transcriptArr[q] = transcriptArr[q].slice(0, -1)
          if (transcriptArr[q] === 'ноль') transcriptArr[q] = 0
        }
        console.log('start', transcript)

        for (let i = 0; i < transcriptArr.length; i++) {
          const word = transcriptArr[i].toLowerCase()
          const secondWord = transcriptArr[i + 1]
          const thirdWord = transcriptArr[i + 2]
          let prevWord = null
          if(i > 0) prevWord = transcriptArr[i - 1].toLowerCase()
          // Executor
          if (ArrTaskExecutor.includes(word)) {
            let bestResult = null
            let bestScore = 6
            this.usersWithName.forEach(user => {
              const currentResult = this.levenshteinDistance(secondWord, user.name)
              const currentResult2 = this.levenshteinDistance(secondWord, user.surname)
              if (currentResult < bestScore) {
                bestScore = currentResult
                bestResult = user
              }
              if (currentResult2 < bestScore) {
                bestScore = currentResult2
                bestResult = user
              }
            })

            if (bestScore <= 4) {
              this.selectedUser = bestResult.name
              this.selectedUserID = bestResult.id
            }
          }
          // Title
          if (ArrTaskTitle.includes(word)) {
            const res = transcriptArr.slice(i + 1).join(' ')
            this.taskTitle = res
            return true
          }
          // Descriptopn
          if (ArrTaskDescription.includes(word)) {
            const res = transcriptArr.slice(i + 1).join(' ')
            this.taskText = res
            return true
          }
          // Type
          if (ArrTaskType.includes(word)) {
            let bestScore = 5
            let bestResult = null
            this.typeList.forEach(type => {
              let currentResult = null
              if (secondWord) currentResult = this.levenshteinDistance(secondWord, type.title)
              if (thirdWord) currentResult = (currentResult < this.levenshteinDistance(thirdWord, type.title)) ? currentResult : this.levenshteinDistance(thirdWord, type.title)
              if ((currentResult < bestScore) && (currentResult !== null)) {
                bestScore = currentResult
                bestResult = type
              }
            })
            if (bestScore <= 4) this.selectedTypeID = bestResult.id
            return true
          }
          // priority
          if (ArrTaskPriority.includes(word)) {
            if (transcript.includes('очень низкий')) this.selectedPriority = 'very_low'
            else if (transcript.includes('низкий')) this.selectedPriority = 'low'
            else if (transcript.includes('средний')) this.selectedPriority = 'average'
            else if (transcript.includes('очень высокий')) this.selectedPriority = 'very_high'
            else if (transcript.includes('высокий')) this.selectedPriority = 'high'
            return true
          }
          // deadline
          if (ArrTaskDeadline.includes(word)) {
            voiceData(transcriptArr, 'deadline')
            return true
          }
          // checkpoint
          if (ArrTaskCheckpoint.includes(word)) {
            voiceData(transcriptArr, 'checkpoint')
            return true
          }
          // checkbox
          if (ArrTaskCheckbox.includes(word)) {
            voiceData(transcriptArr, 'checkbox')
            return true
          }
          // submit
          if (ArrTaskSubmit.includes(word)) {
            // if (this.deadline && this.selectedUserID && this.taskTitle) {
              this.submit()
              // this.voiceEnable = false
            // }
            // else if (!this.deadline) this.error_create = 'Выберите дедлайн'
            // else if (!this.selectedUserID) this.error_create = 'Выберите исполнителя'
            // else if (!this.selectedUserID) this.error_create = 'Введите название задачи'
            // return true
          }
          // stop
          // if (ArrTaskStop.includes(word)) {
          //   this.voiceEnable = false
          // }
          // close
          if (ArrTaskClose.includes(word)) {
            this.voiceEnable = false
            this.hideWindow()
          }
        }
      }

      this.recognition.onend = async () => {
        this.recognition.stop()
        if (this.voiceEnable) this.recognition.start()
      }
      this.recognition.start()
    }
  },
  watch: {
    selectedTypeID () {
      if (this.selectedTypeID) {
        const selectedType = this.typeList.find(type => type.id === this.selectedTypeID)
        this.selectedType = selectedType.title
      } else {
        this.selectedType = ''
      }
    },
    show () {
      if (this.show) {
        this.voiceEnable = true
        this.startListening()
      } else {
        this.voiceEnable = false
        this.recognition.stop()
      }
    }
  },
  async mounted () {
    getUserActive()
      .then(res => {
        this.userId = res.id
        // this.organizationID = res.organization_id[0]
        if (res.convener_organization_ids[0]) this.organizationID = res.convener_organization_ids[0]
        else this.organizationID = res.member_organization_ids[0]
        this.getUsersList()
        this.getTypeList()
        getOrganizationsUsers(this.organizationID)
          .then((res) => {
            res.forEach(user => {
              getUserById(user.user_id)
                .then(res => {
                  this.orgUsers.push(res.data)
                })
            })
          })
      })
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap');
.user-selector .body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.create_task {
  width: 500px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;

}
.create_task_logo {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.create_task_logo p {
  position: relative;
  top: 12px;
  left: 5px;
  width: 142px;
  height: 20px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
}
.container {
  background: #3E3E42;
  border-radius: 25px;
  padding: 20px;
  position: relative;
  width: 514px;
  height: 800px;
  overflow-y: hidden;
  overflow-x: hidden;

}
.input-container {
  position: relative;
}
.btn_created {
  width: 70px;
  height: 30px;
  background: #405cd5;
}
.btn_created:disabled {
  pointer-events: none;
  opacity: 0.3;
}
.delete_minitask {
  position: absolute;
  top: 10px;
  right: 36px;
  border: none;
}
.typeselect {
  width: 267px;
  height: 40px;
  margin-right: 25px;
  background: #6A6A70;
  border: 1px solid #82838A;
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: white;
}
.container input {
  position: relative;
  left: -25px;
  width: 267px;
  background: #6A6A70;
  border: 1px solid #82838A;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  padding-right: 45px;
  color: white;
}
::placeholder {
  color: white;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
}
.dropdown {
  position: relative;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.user-selector {
  font-size: 14px;
  margin-left: 5px;
  padding: 10px;
  cursor: pointer;
}
.user-selector .dropdown-menu li:hover {
  background-color: #a08585;
}
.mini-tasks input {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
}
.mini-tasks button {
  margin-left: 10px;
  cursor: pointer;
  background-color: #efefef;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: black;
}
.mini-tasks button:hover {
  background-color: #dcdcdc;
}
.user-selector .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-bottom: 15px;
}
.user-selector .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #405cd5;
  cursor: pointer;
}
.user-selector .slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #405cd5;
  cursor: pointer;
}
.type-list {
  list-style: none;
  width: 400px;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
}
.type-list li {
  padding: 5px 10px;
  cursor: pointer;
}
.type-list li:hover {
  background-color: #ccc;
}
.loading {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loading:before {
  content: '';
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: loading 1.2s linear infinite;
}
.start-task-btn, .start-task-btn-dark {
  position: relative;
  width: 154px;
  height: 40px;
  border: none;
  border-radius: 5.53226px;
  font-size: 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: .5s;
}
.start-task-btn {
  background-color: var(--light-task-completed-bg);
  color: var(--light-task-color);
}
.start-task-btn:hover {
  background-color: var(--light-task-completed-bg-hover);
}
.start-task-btn-dark {
  background-color: var(--dark-task-completed-bg);
  color: var(--dark-task-color);
}
.start-task-btn-dark:hover {
  background-color: var(--dark-task-completed-bg-hover);
}
.complete-task-btn, .complete-task-btn-dark {
  position: relative;
  width: 154px;
  height: 40px;
  background: #3F5D80;
  color: white;
  border: none;
  text-align: center;
  border-radius: 5.53226px;
  font-size: 15px;
  transition: .5s;
}
.complete-task-btn {
  background: var(--light-task-working-bg);
  color: var(--light-task-color);
}
.complete-task-btn:hover {
  background: var(--light-task-working-bg-hover);
}
.complete-task-btn-dark {
  background: var(--dark-task-working-bg);
  color: var(--dark-task-color);
}
.complete-task-btn-dark:hover {
  background: var(--dark-task-working-bg-hover);
}
.vioce_resp, .vioce_resp-dark {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vioce_resp {
  background: var(--light-task-new-bg);
}
.vioce_resp:hover {
  background: var(--light-task-new-bg-hover);
}
.vioce_resp-dark {
  background: var(--dark-task-new-bg);
}
.vioce_resp-dark {
  background: var(--dark-task-new-bg-hover);
}

.vioce_resp span {
  font-size: 15px;
}

.vioce_resp img {
  width: 20px;
  height: 20px;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* Set the background of the Popup */
.inner-wrapper .k-calendar,
.inner-wrapper .k-calendar table {
background-color: #8bc34a;
}

/* Set the text color of the different elements inside the Popup */
.inner-wrapper .k-calendar,
.inner-wrapper .k-calendar table thead,
.inner-wrapper .k-calendar,
.inner-wrapper .k-calendar table td {
color: #ffff00;
}

/* Style the current date and the "Today" button */
.inner-wrapper .k-calendar table td.k-today span.k-link,
.inner-wrapper .k-calendar div.k-calendar-header span {
color: #0000ff;
}

</style>
