
<template>
  <div class="organization-usersinfo">
    <NavigationLayout></NavigationLayout>
    <div class="organization-usersinfo-content">
      <div class="title">Участники:</div>
      <div v-for="user in NotBlockedUsers" :key="user.id" class="organization-management">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <button @click="blockUser(user.trueId)" class="layout-header-btn">Удалить участника</button>
      </div>
      <div v-if="organizationUsersWaiting.length" class="title">Ожидают вступления:</div>
      <div v-for="user in organizationUsersWaiting" :key="user.id" class="">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <div style="display: flex; gap: 12px">
          <button @click="acceptUser(user.trueId)" class="layout-header-btn"> принять</button>
          <button @click="rejectUser(user.trueId)" class="layout-header-btn"> отказать</button>
        </div>
      </div>
      <div v-if="blockedUsers.length">Заблокированные:</div>
      <div v-for="user in blockedUsers" :key="user.id" class="organization-management">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <button @click="unblockUser(user.trueId)" class="layout-header-btn">Разблокировать</button>
      </div>
    </div>
  </div>
</template>

<script>

import { getOrganizationByUserId, getOrganizationsUsers, getUserById } from '@/api/get'
import { acceptInviteToOrganization, rejectInviteToOrganization, blockUserInOrganization, unblockUserInOrganization } from '@/api/patch'

import NavigationLayout from '@/components/AllPage/NavigationLayout.vue'

export default {
  components: {
    NavigationLayout
  },
  data () {
    return {
      userId: this.$cookies.get('ID'),
      organizationId: null,
      organizationUsers: [],
      organizationUsersWaiting: []
    }
  },
  methods: {
    acceptUser (userId) {
      acceptInviteToOrganization(this.organizationId, userId)
      this.updateOrganization()
    },
    rejectUser (userId) {
      rejectInviteToOrganization(this.organizationId, userId)
      this.updateOrganization()
    },
    blockUser (userId) {
      blockUserInOrganization(this.organizationId, userId)
      this.updateOrganization()
    },
    unblockUser (userId) {
      unblockUserInOrganization(this.organizationId, userId)
      this.updateOrganization()
    },
    updateOrganization () {
      this.organizationUsersWaiting = []
      this.organizationUsers = []
      getOrganizationByUserId(this.userId)
        .then((reslt) => {
          this.organizationId = reslt.organization_id
          getOrganizationsUsers(this.organizationId)
            .then((result) => {
              // eslint-disable-next-line
              result.map(user => {
                getUserById(user.user_id)
                  .then(res => {
                    const data = { ...res.data, trueId: user.id, is_blocked: user.is_blocked }
                    if (user.status === 'WAITING') this.organizationUsersWaiting.push(data)
                    else if ((user.status === 'ACCEPTED')) this.organizationUsers.push(data)
                  })
              })
            })
        })
    }
  },
  computed: {
    blockedUsers () {
      return this.organizationUsers.filter(user => user.is_blocked === true)
    },
    NotBlockedUsers () {
      return this.organizationUsers.filter(user => user.is_blocked === false)
    }
  },
  beforeMount () {
    this.updateOrganization()
  }

}

</script>

<style scoped>
  .title {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .organization-usersinfo {
    display: flex;
    justify-content: start !important;
    align-items: start !important;
  }
  .organization-management {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
</style>
