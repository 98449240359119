import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueCookies from 'vue-cookies'
import components from '@/components'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies)

components.forEach(component => { app.component(component.name, component) })

app.mount('#app')

function subscribeUser () {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array('YOUR_PUBLIC_KEY')
      }).then(function (subscription) {
        console.log('User is subscribed:', subscription)

        // Отправка подписки на сервер
        fetch('/subscribe', {
          method: 'POST',
          body: JSON.stringify(subscription),
          headers: {
            'Content-Type': 'application/json'
          }
        })
      }).catch(function (err) {
        console.log('Failed to subscribe the user: ', err)
      })
    })
  } else {
    console.warn('Push messaging is not supported')
  }
}

// Преобразование base64 ключа в Uint8Array
function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
  // eslint-disable-next-line
    .replace(/\-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

// Вызов функции подписки после монтирования приложения
// app.mount('#app').then(() => {
//   subscribeUser()
// })
subscribeUser()
